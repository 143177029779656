body {
  background-color: lightgray;
  height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}
